import React from "react";
import { connectHits } from "react-instantsearch-dom";
import { format } from "date-fns";

import { Image } from "../../Image";
import { PersonImage } from "../../Images";

import { Link } from "../Link";

import { Highlight, Snippet } from "./Highlight";

export const colorList = ["green", "purple", "blue", "yellow", "orange", "red"];

const colorPalette = {
  orange: "bg-orange-100 text-orange-900",
  purple: "bg-purple-100 text-purple-900",
  blue: "bg-blue-100 text-blue-900",
  green: "bg-green-100 text-green-900",
  yellow: "bg-yellow-100 text-yellow-900",
  red: "bg-red-100 text-red-900",
  gray: "bg-gray-100 text-gray-900",
};

export const Label = ({ className = "", color = "gray", label, ...rest }) => {
  const colorCls = colorPalette[color] || colorPalette["gray"];
  return label ? (
    <span
      {...rest}
      className={`inline-flex items-center px-1.5 py-0.5 my-0.5 rounded-full font-jaf text-sm font-medium ${colorCls} ${className}`}
    >
      {label}
    </span>
  ) : null;
};

export const RainbowLabel = ({ label }) => (
  <Label color={colorList[label?.length % colorList?.length]} label={label} />
);

export const TypenameLabel = ({ typename, ...rest }) => {
  const color = {
    "Tumor Type": "orange",
    Project: "purple",
    Publication: "blue",
    Presentation: "green",
    Institution: "yellow",
  }[typename];

  return (
    <Label
      {...rest}
      color={color}
      className={`absolute z-10 top-0 left-0 rounded-none rounded-tl-md rounded-br-md`}
      label={typename}
    />
  );
};

export const Hit = ({ hit }) => (
  <li
    key={hit.objectID}
    className="flex flex-col items-center space-y-4 w-full h-full"
  >
    <Link
      href={hit.slug}
      className="max-w-[300px] flex flex-col space-y-2 rounded-md focus:outline-none focus:ring-2 focus:ring-offset-8 focus:ring-navy-800 transform transition duration-200"
    >
      <div className="relative flex items-center w-full justify-center">
        {hit.typename !== "Research Partner" && (
          <TypenameLabel typename={hit.typename} />
        )}
        <Image
          src={
            hit.image.src || "https://cbtn-s3.imgix.net/Asset_22_5bY7mEM.png"
          }
          {...(hit.typename === "Research Partner"
            ? {
                className: "rounded-full",
              }
            : {
                className: "rounded-md rounded-t-lg",
              })}
        />
      </div>

      <div className="flex flex-col flex-1 space-y-2">
        <Highlight
          attribute={"title"}
          hit={hit}
          className="font-jaf text-lg leading-5 text-gray-800"
          alwaysShow={true}
        />
        <Snippet attribute={"description"} hit={hit} alwaysShow={true} />
        <Snippet attribute={"authorsText"} hit={hit} />
        <Highlight attribute={"principalInvestigator.title"} hit={hit} />
        <Highlight
          attribute={"principalInvestigator.memberInstitution.title"}
          hit={hit}
        />
      </div>
    </Link>
  </li>
);

const PrioritizedSnippet = ({ defaultAttribute, attributes, hit, ...rest }) => {
  let attribute = [defaultAttribute, ...attributes].find(
    (attribute) => hit._snippetResult[attribute]?.value?.length
  );
  attribute =
    attributes.find(
      (attribute) => hit._snippetResult[attribute]?.matchLevel === "partial"
    ) || attribute;
  attribute =
    attributes.find(
      (attribute) => hit._snippetResult[attribute]?.matchLevel === "full"
    ) || attribute;

  return attribute ? (
    <Snippet attribute={attribute} hit={hit} {...rest} />
  ) : null;
};

export const ProjectHit = ({ hit, addLabel = true }) => (
  <li
    key={hit.objectID}
    className="flex flex-col items-center space-y-4 w-full h-full"
  >
    <Link
      href={`/projects/${hit.slug}`}
      className="max-w-[300px] flex flex-col space-y-2 rounded-md focus:outline-none focus:ring-2 focus:ring-offset-8 focus:ring-navy-800 transform transition duration-200"
    >
      <div className="relative flex items-center w-full justify-center">
        {addLabel && <TypenameLabel typename={hit.typename} />}
        <Image
          src={
            hit.image.src || "https://cbtn-s3.imgix.net/Asset_22_5bY7mEM.png"
          }
          alt={hit.title}
          layout="intrinsic"
          objectFit="cover"
          height="150"
          width="300"
          className="rounded-md rounded-t-lg"
        />
      </div>
      <div className="flex flex-wrap space-x-1">
        {hit.experimentTypes?.map(({ name }) => (
          <RainbowLabel key={name} label={name} />
        ))}
        <RainbowLabel label={hit.status?.name} />
      </div>
      <div className="flex flex-col flex-1 space-y-2">
        <Highlight
          attribute={"title"}
          hit={hit}
          className="font-jaf text-lg leading-5 !text-gray-900"
          alwaysShow={true}
        />
        <PrioritizedSnippet
          defaultAttribute={"summary"}
          attributes={["description", "summary"]}
          hit={hit}
          alwaysShow={true}
        />
        <div className="flex flex-wrap space-x-1">
          {hit.histologies?.map((value, idx) => (
            <Highlight
              key={value.title}
              hit={hit}
              attribute={`histologies[${idx}].title`}
              className="italic"
            />
          ))}
          {hit.experimentThemes?.map((value, idx) => (
            <Highlight
              key={value.name}
              hit={hit}
              attribute={`experimentThemes[${idx}].name`}
              className="italic"
            />
          ))}
        </div>
        {hit?.principalInvestigator?.title && (
          <div className="flex">
            <div className="flex mr-4 flex-shrink-0 self-center">
              <Image
                src={
                  hit.principalInvestigator.image?.src ||
                  "https://cbtn-s3.imgix.net/Asset_22_5bY7mEM.png"
                }
                layout="intrinsic"
                objectFit="cover"
                height="32"
                width="32"
                className="h-8 w-8 rounded-full object-cover"
                alt={hit.principalInvestigator.title}
              />
            </div>
            <div>
              <Highlight
                attribute={"principalInvestigator.title"}
                hit={hit}
                alwaysShow={true}
                className="!text-gray-900"
              />
              <Highlight
                attribute={"principalInvestigator.memberInstitution.title"}
                hit={hit}
                alwaysShow={true}
              />
            </div>
          </div>
        )}
      </div>
    </Link>
  </li>
);

export const PersonHit = ({ hit }) => (
  <li
    key={hit.objectID}
    className="flex flex-col items-center space-y-4 w-full h-full"
  >
    <Link
      href={`/research-partners/${hit.slug}`}
      className="max-w-[300px] flex flex-col space-y-2 rounded-md focus:outline-none focus:ring-2 focus:ring-offset-8 focus:ring-navy-800 transform transition duration-200"
    >
      <div className="relative flex items-center w-full justify-center">
        <PersonImage
          image={hit.image}
          height="150"
          width="150"
          imgixParams={{ q: 100 }}
          className="w-40 h-40"
        />
      </div>
      <div className="flex flex-wrap justify-center space-x-1">
        {hit.leadershipTypes
          ?.sort((a, b) => a.name.length - b.name.length)
          .map(({ name }) => (
            <RainbowLabel key={name} label={name} />
          ))}
      </div>
      <div className="flex flex-col text-center flex-1 space-y-2">
        <Highlight
          attribute={"title"}
          hit={hit}
          className="font-jaf text-lg leading-5 !text-gray-900"
          alwaysShow={true}
        />
        {hit?.memberInstitution?.title && (
          <>
            <Highlight
              attribute={"memberInstitution.title"}
              hit={hit}
              alwaysShow={true}
              className="!text-gray-900"
            />
            <p className={`text-sm text-gray-700`}>
              {hit?.memberInstitution?.location}
            </p>
          </>
        )}
      </div>
    </Link>
  </li>
);

export const PubPresHit = ({ hit, path, addLabel = true }) => (
  <li
    key={hit.objectID}
    className="flex flex-col items-center space-y-4 w-full h-full"
  >
    <Link
      href={`/${path}/${hit.slug}`}
      className="max-w-[300px] flex flex-col w-full h-full space-y-2 rounded-md focus:outline-none focus:ring-2 focus:ring-offset-8 focus:ring-navy-800 transform transition duration-200"
    >
      <div className="relative flex items-center w-full justify-center overflow-hidden aspect-w-16 aspect-h-9">
        {addLabel && (
          <div className="absolute -my-0.5">
            <TypenameLabel typename={hit.typename} />
          </div>
        )}
        <Image
          src={
            hit.image.src ||
            "https://cbtn.org/static/undraw_secure_data_0rwp-9a6cf549e432e50fb00c93d9381b2902.svg"
          }
          alt={hit.title}
          height={300}
          width={600}
          imgixParams={{ q: 100 }}
          className="rounded-md rounded-t-lg object-contain"
        />
      </div>
      <div className="flex flex-wrap space-x-1">
        <Label label={hit.journal?.name} />
        <Label label={hit.conference?.name} />
      </div>
      <div className="flex flex-col flex-1 space-y-2">
        <Highlight
          attribute={"title"}
          hit={hit}
          className="font-jaf text-lg leading-5 !text-gray-900"
          alwaysShow={true}
        />
        {hit?.dateTimestamp && (
          <time className="text-sm italic" dateTime={hit?.dateTimestamp}>
            {format(new Date(hit?.dateTimestamp), "MMMM dd, yyyy")}
          </time>
        )}
        <PrioritizedSnippet
          defaultAttribute={"summary"}
          attributes={["description", "summary"]}
          hit={hit}
        />
        <Snippet attribute={"authorsText"} hit={hit} className="italic" />

        <div className="flex flex-wrap space-x-1">
          {hit.authors?.map((value, idx) => (
            <Highlight
              key={value.memberInstitution?.title}
              hit={hit}
              attribute={`authors[${idx}].memberInstitution.title`}
              className="italic"
            />
          ))}
        </div>
      </div>
    </Link>
  </li>
);

export const _Hits = ({ hits, Hit: Comp = Hit, ...rest }) => (
  <ul className="m-auto max-w-7xl grid grid-cols-1 gap-y-10 gap-x-6 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4">
    {hits.map((hit) => (
      <Comp key={hit.objectID} hit={hit} {...rest} />
    ))}
  </ul>
);

export const Hits = connectHits(_Hits);
