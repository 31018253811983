import React from "react";
import { SearchIcon } from "@heroicons/react/outline";
import { connectSearchBox } from "react-instantsearch-dom";

export const _SearchBox = ({ currentRefinement, refine, inputRef }) => (
  <form noValidate action="" role="search">
    <label htmlFor="search" className="sr-only">
      Search
    </label>
    <div className="relative">
      <div className="absolute inset-y-0 left-0 pl-4 flex items-center pointer-events-none sm:pl-8">
        <SearchIcon
          className="text-blue-500 h-4 w-4 sm:h-8 sm:w-8"
          aria-hidden="true"
        />
      </div>

      <input
        name="search"
        className="block w-full pl-10 pr-1 py-3 rounded-sm border-none leading-4 text-lg font-jaf font-light bg-gray-50 placeholder-gray-400 focus:outline-none focus:ring-gray-400 sm:pl-20 sm:pr-3 sm:py-6 sm:leading-5 sm:text-2xl"
        placeholder="Search for projects, publications and more"
        type="text"
        id="search"
        autoComplete="off"
        autoCorrect="off"
        autoCapitalize="none"
        spellCheck="false"
        ref={inputRef}
        value={currentRefinement}
        onChange={(event) => refine(event.currentTarget.value)}
      />
    </div>
  </form>
);

export const SearchBox = connectSearchBox(_SearchBox);

export const _InlineSearchInput = ({ currentRefinement, refine, inputRef }) => (
  <div className="flex-1 flex justify-between px-4 sm:px-6 lg:px-8">
    <div className="flex-1 flex">
      <form
        className="w-full flex md:ml-0"
        noValidate
        action=""
        role="search"
        onSubmit={(e) => {
          e.preventDefault();
          (event) => refine(event.currentTarget.value);
        }}
      >
        <label htmlFor="search_field" className="sr-only">
          Search
        </label>
        <div className="relative w-full text-gray-400 focus-within:text-gray-600">
          <div className="absolute inset-y-0 left-0 flex items-center pointer-events-none">
            <SearchIcon className="h-5 w-5" aria-hidden="true" />
          </div>
          <input
            id="query"
            name="query"
            className="block w-full h-full pl-8 pr-3 py-2 border-transparent text-gray-900 placeholder-gray-500 focus:outline-none focus:ring-0 focus:border-transparent focus:placeholder-gray-400 sm:text-sm"
            placeholder="Search"
            type="search"
            autoComplete="off"
            autoCorrect="off"
            autoCapitalize="none"
            spellCheck="false"
            ref={inputRef}
            defaultValue={currentRefinement}
            onChange={(event) => refine(event.currentTarget.value)}
          />
        </div>
      </form>
    </div>
  </div>
);

export const InlineSearchInput = connectSearchBox(_InlineSearchInput);
