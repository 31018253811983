import React from "react";
import { Disclosure } from "@headlessui/react";
import { ChevronRightIcon } from "@heroicons/react/solid";
import { useRecoilValue } from "recoil";

import { facetSearchInputValueState } from "./states";

export const Panel = ({
  header,
  children,
  className,
  defaultOpen = true,
  ...rest
}) => {
  const facetSearchInputValue = useRecoilValue(facetSearchInputValueState);
  return (
    <div className={`${className}`} {...rest}>
      <Disclosure defaultOpen={defaultOpen}>
        {({ open }) => {
          open = open || facetSearchInputValue?.length > 0;
          return (
            <>
              <Disclosure.Button className="font-jaf flex items-center w-full px-3 py-2 text-sm text-gray-700 rounded-sm hover:text-gray-900 hover:bg-gray-200 focus:outline-none focus-visible:outline-none focus-visible:bg-navy-200">
                <ChevronRightIcon
                  className={`w-2.5 h-2.5 mr-4 transform ${
                    open ? "rotate-90" : ""
                  }`}
                />
                <h3
                  className="font-jaf text-xs font-semibold text-gray-700 uppercase tracking-wider"
                  id={`${header.replace(" ", "-")}-headline`}
                >
                  {header}
                </h3>
              </Disclosure.Button>
              <Disclosure.Panel static>
                <div
                  role="group"
                  aria-labelledby={`${header.replace(" ", "-")}-headline`}
                >
                  {children({ open })}
                </div>
              </Disclosure.Panel>
            </>
          );
        }}
      </Disclosure>
    </div>
  );
};
