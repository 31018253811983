import React from "react";
import { connectStateResults } from "react-instantsearch-dom";

export const Results = connectStateResults(
  ({ searchState, searchResults, children }) => {
    if (searchResults) {
      if (searchResults.nbHits === 0) {
        return (
          <h2 className="text-center text-3xl text-gray-900 tracking-tight mt-12 sm:text-4xl">
            No results have been found for{" "}
            <span className="text-blue-500">{searchState.query}</span>.
          </h2>
        );
      }
      return children;
    }
    return (
      <h2 className="text-center text-3xl text-gray-900 tracking-tight mt-12 sm:text-4xl">
        <span className="text-blue-500">Loading...</span>
      </h2>
    );
  }
);
