import React from "react";
import { Link as GatsbyLink } from "gatsby";

export const Link = ({ className = "", children, href }) =>
  !href.startsWith("/") ? (
    <a target="_blank" rel="noreferrer" className={className}>
      {children}
    </a>
  ) : (
    <GatsbyLink to={href} className={className}>
      {children}
    </GatsbyLink>
  );
