import React from "react";
import { connectPagination } from "react-instantsearch-dom";

import { Button } from "../../Button";

const _Pagination = ({ currentRefinement, nbPages, refine }) => (
  <nav className="bg-white py-3 flex items-center" aria-label="Pagination">
    <div className="flex-1 flex justify-end space-x-2">
      {currentRefinement > 1 && (
        <Button
          onClick={(e) => {
            e.preventDefault();
            refine(currentRefinement - 1);
          }}
          className="relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
        >
          Previous
        </Button>
      )}
      {currentRefinement < nbPages && (
        <Button
          onClick={(e) => {
            e.preventDefault();
            refine(currentRefinement + 1);
          }}
          className="relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
        >
          Next
        </Button>
      )}
    </div>
  </nav>
);

export const Pagination = connectPagination(_Pagination);
