import React, { useEffect, useState, memo } from "react";
import { connectRefinementList } from "react-instantsearch-dom";
import { CheckIcon } from "@heroicons/react/solid";
import { useRecoilValue } from "recoil";

import { Highlight } from "./Highlight";
import { Panel } from "./Panel";
import { facetSearchInputValueState } from "./states";

export const _RefinementList = ({
  items,
  isFromSearch,
  refine,
  searchForItems,
  searchable,
  setHidden,
  setHasMore,
  limit,
  open,
}) => {
  const facetSearchInputValue = useRecoilValue(facetSearchInputValueState);
  const [adjustedLimit, setAdjustedLimit] = useState(limit);

  useEffect(() => {
    setAdjustedLimit(limit - 1);
  }, [limit, setAdjustedLimit]);

  useEffect(() => {
    if (searchable && facetSearchInputValue !== null) {
      searchForItems(facetSearchInputValue);
    }
  }, [searchable, facetSearchInputValue, searchForItems]);

  useEffect(() => {
    setHidden(
      items?.length === 0 || (!searchable && facetSearchInputValue?.length > 0)
    );
  }, [items.length, setHidden, searchable, facetSearchInputValue]);

  useEffect(() => {
    setHasMore(items?.length > adjustedLimit);
  }, [items?.length, setHasMore, adjustedLimit]);

  return (
    <ul>
      {items.slice(0, adjustedLimit).map((item) => (
        <li key={item.label} className={item.isRefined || open ? "" : "hidden"}>
          <button
            onClick={(event) => {
              event.preventDefault();
              refine(item.value);
            }}
            className="group font-jaf flex w-full items-center px-3 py-1 text-sm text-gray-700 hover:text-gray-900 hover:bg-gray-50 focus:outline-none focus:bg-navy-200"
          >
            {item.isRefined ? (
              <CheckIcon className="w-2.5 h-2.5 mr-4" />
            ) : (
              <span
                className="bg-gray-50 border border-gray-400 w-2.5 h-2.5 mr-4 rounded-sm"
                aria-hidden="true"
              />
            )}
            <span className="flex items-center justify-between w-full space-x-1">
              <span
                className={`text-left ${
                  item.isRefined ? "group-hover:line-through" : ""
                }`}
              >
                {isFromSearch ? (
                  <Highlight attribute="label" hit={item} />
                ) : (
                  item.label
                )}
              </span>
              <span className="inline-flex items-center px-2.5 py-0.5 rounded-md font-jaf text-sm font-medium mr-2 bg-gray-200 text-gray-900">
                {item.count}
              </span>
            </span>
          </button>
        </li>
      ))}
    </ul>
  );
};

export const RefinementList = connectRefinementList(memo(_RefinementList));

export const RefinementPanel = ({ header, limit = 10, ...rest }) => {
  const [hidden, setHidden] = useState(false);
  const [currentLimit, setCurrentLimit] = useState(limit);
  const [hasMore, setHasMore] = useState(false);

  return (
    <Panel header={header} className={`${hidden ? "hidden" : "block"}`}>
      {({ open }) => (
        <>
          <RefinementList
            {...rest}
            open={open}
            limit={currentLimit + 1}
            setHasMore={setHasMore}
            setHidden={setHidden}
          />
          {open && (
            <>
              {hasMore && currentLimit > limit * 2 && (
                <p className="text-sm italic text-gray-700 px-3">
                  Try using the search above to find your value
                </p>
              )}
              <div className="flex justify-end px-3">
                {currentLimit > limit && (
                  <button
                    onClick={() => setCurrentLimit(limit)}
                    className="font-jaf items-center px-3 py-1 text-sm text-gray-700 rounded-sm hover:text-gray-900 hover:bg-gray-200 focus:outline-none focus-visible:outline-none focus-visible:bg-navy-200"
                  >
                    Show less
                  </button>
                )}
                {hasMore && (
                  <button
                    onClick={() => setCurrentLimit(currentLimit + limit)}
                    className="font-jaf items-center px-3 py-1 text-sm text-gray-700 rounded-sm hover:text-gray-900 hover:bg-gray-200 focus:outline-none focus-visible:outline-none focus-visible:bg-navy-200"
                  >
                    Show more
                  </button>
                )}
              </div>
            </>
          )}
        </>
      )}
    </Panel>
  );
};
