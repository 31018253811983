import React from "react";
import { connectHighlight } from "react-instantsearch-dom";

export const _Highlight = ({
  attribute,
  hit,
  highlight,
  highlightProperty = "_highlightResult",
  className = "",
  alwaysShow = false,
}) => {
  const parsedHit = highlight({
    highlightProperty,
    attribute,
    hit,
  });

  return alwaysShow || parsedHit.some((part) => part.isHighlighted) ? (
    <p className={`text-sm text-gray-700 ${className}`}>
      {parsedHit.map((part, index) =>
        part.isHighlighted ? (
          <span key={index} className="text-blue-500">
            {part.value}
          </span>
        ) : (
          <span key={index}>{part.value}</span>
        )
      )}
    </p>
  ) : null;
};

export const Highlight = connectHighlight(_Highlight);

export const Snippet = (props) => (
  <Highlight highlightProperty="_snippetResult" {...props} />
);
