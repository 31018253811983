import React from "react";
import { InstantSearch as AlgoliaInstantSearch } from "react-instantsearch-dom";
import algoliasearch from "algoliasearch/lite";

const algoliaClient = algoliasearch(
  process.env.GATSBY_ALGOLIA_APP_ID,
  process.env.GATSBY_ALGOLIA_SEARCH_API_KEY
);
export const InstantSearch = (props) => (
  <AlgoliaInstantSearch
    searchClient={algoliaClient}
    indexName={process.env.GATSBY_ALGOLIA_INDEX_NAME}
    {...props}
  />
);
